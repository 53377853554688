import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import exp from "node:constants";
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {

	if(locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/wl-advantages-1.png"
						alt="Без комиссии агрегаторов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Агрегаторлардың комиссиясынсыз",
				description:
					"Курьерлер штатыңызды жинаңыз. \nӨзіңіздің жеткізуіңізді ұйымдастырыңыз\nжәне бөгде сервистерге комиссия\nтөлемеңіз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/wl-advantages-2.png"
						alt="Всегда на связи с гостями"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Әрқашан қонақтармен байланыста",
				description:
					"Қосымшаны жиірек пайдалануға ынталандырыңыз. Қонақтарға акциялар, жеңілдіктер, жинақталған баллдар туралы хабарлаңыз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/wl-advantages-3.png"
						alt="Да, это бесплатно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Иә, бұл тегін",
				description:
					"Бөгде дизайнерлер мен студиясыз дайын қосымша және сайт. Бірінші тапсырысты тіпті келесі күні де қабылдаңыз.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/wl-advantages-1.png"
					alt="Без комиссии агрегаторов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Без комиссии агрегаторов",
			description:
				"Собери свой штат курьеров. \nОрганизуй собственную доставку \nи&nbsp;не&nbsp;плати комиссию сторонним \nсервисам.",
		},
		{
			img: (
				<StaticImage
					src="./assets/wl-advantages-2.png"
					alt="Всегда на связи с гостями"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Всегда на связи с гостями",
			description:
				"Мотивируй пользоваться приложением чаще. Оповещай гостей об&nbsp;акциях, скидках, накопленных баллах.",
		},
		{
			img: (
				<StaticImage
					src="./assets/wl-advantages-3.png"
					alt="Да, это бесплатно"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Без скрытых платежей",
			description: <>Готовый набор инструментов <br/>для привлечения, вовлечения и&nbsp;удержания гостей с&nbsp;оплатой по&nbsp;подписке.</>,
		},
	]
}

export const advantagesSlides = [
	{
		img: (
			<StaticImage
				src="./assets/wl-advantages-1.png"
				alt="Без комиссии агрегаторов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Без комиссии агрегаторов",
		description:
			"Собери свой штат курьеров. \nОрганизуй собственную доставку \nи&nbsp;не&nbsp;плати комиссию сторонним \nсервисам.",
	},
	{
		img: (
			<StaticImage
				src="./assets/wl-advantages-2.png"
				alt="Всегда на связи с гостями"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Всегда на связи с гостями",
		description:
			"Мотивируй пользоваться приложением чаще. Оповещай гостей об&nbsp;акциях, скидках, накопленных баллах.",
	},
	{
		img: (
			<StaticImage
				src="./assets/wl-advantages-3.png"
				alt="Да, это бесплатно"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Да, это бесплатно",
		description:
			"Готовое приложение для кафе и&nbsp;сайт без сторонних дизайнеров и&nbsp;студии. Разработка бесплатна, оплачивается только подписка.",
	},
]
